<template>
<nav class="navbar-disturbance">
  <div class="logo">
    <router-link class="nav-link" to="/home"><img id="rftlogo" src="../assets/img/rft.png" alt="#"></router-link>
  </div>
  <div class="symbols-container">
    <span class="warning material-symbols-outlined">
          warning
      </span>
    <span class="disturbance-brand">STÖ-WA-ME</span>
    <span class="warning material-symbols-outlined">
          warning
      </span>
  </div>
  <div class="expand" @click="toggleCollapse">
    <span v-if="!isCollapsed" class="circle material-symbols-outlined">
          expand_circle_up
    </span>
    <span v-if="isCollapsed" class="circle material-symbols-outlined">
          expand_circle_down
    </span>
  </div>
  <div v-if="isCollapsed" class="link-container collapsed">
    <div class="links">
      <div class="navigation-link navigation-link-border"><router-link class="nav-link" to="/disturbance-editor"><span class="text-white link-text">Störung melden</span></router-link></div>
      <div class="navigation-link navigation-link-border"><router-link class="nav-link" to="/maintenance-editor"><span class="text-white link-text">Wartung eintragen</span></router-link></div>
      <div class="navigation-link navigation-link-border"><router-link class="nav-link" to="/internal-disturbance"><span class="text-white link-text">Interne Störung/Wartungsarbeiten</span></router-link></div>
      <div class="navigation-link navigation-link-border"><router-link class="nav-link" to="/overview"><span class="text-white link-text">Störungsübersicht</span></router-link></div>
      <div class="navigation-link navigation-link-border"><router-link class="nav-link" to="/maintenance-overview"><span class="text-white link-text">Wartungsübersicht</span></router-link></div>
      <div class="navigation-link navigation-link-border"><router-link class="nav-link" to="/evaluation"><span class="text-white link-text">Auswertung</span></router-link></div>
    </div>
    <div class="links-end">
      <div v-if="!isAuthenticated" class='navigation-link'><router-link class='nav-link' to="/login"><span class='text-white link-text'>Anmelden</span></router-link></div>
      <div v-if="isAuthenticated && isAdmin" class='navigation-link'><router-link class='nav-link' to="/administration"><span class='text-white link-text'>Administration</span></router-link></div>
      <div v-if="isAuthenticated" class='navigation-link' @click="logout"><a><span class='text-white link-text'>Abmelden</span></a></div>
    </div>
  </div>

  <div v-if="!isCollapsed" class="link-container">
    <div class="links">
      <div class="navigation-link"><router-link class="nav-link" to="/disturbance-editor"><span class="text-white h4">Störung melden</span></router-link></div>
      <div class="navigation-link"><router-link class="nav-link" to="/maintenance-editor"><span class="text-white h4">Wartung eintragen</span></router-link></div>
      <div class="navigation-link"><router-link class="nav-link" to="/internal-disturbance"><span class="text-white h4">Interne Störung/Wartungsarbeiten</span></router-link></div>
      <div class="navigation-link"><router-link class="nav-link" to="/overview"><span class="text-white h4">Störungsübersicht</span></router-link></div>
      <div class="navigation-link"><router-link class="nav-link" to="/maintenance-overview"><span class="text-white h4">Wartungsübersicht</span></router-link></div>
      <div class="navigation-link"><router-link class="nav-link" to="/evaluation"><span class="text-white h4">Auswertung</span></router-link></div>
    </div>
    <div class="links-end">
      <div v-if="!isAuthenticated" class='navigation-link'><router-link class='nav-link' to="/login"><span class='text-white h4'>Anmelden</span></router-link></div>
      <div v-if="isAuthenticated && isAdmin" class='navigation-link'><router-link class='nav-link' to="/administration"><span class='text-white h4'>Administration</span></router-link></div>
      <div v-if="isAuthenticated" class='navigation-link' @click="logout"><a><span class='text-white h4'>Abmelden</span></a></div>
    </div>
  </div>
</nav>
</template>

<script>
import { validateToken, userIsAdmin } from './LoginScreen.vue';
import { post } from '../api.js';
export default {
  name: "NavBar",
  data() {
    return {
      isAuthenticated: false,
      isAdmin: false,
      isCollapsed: true
    };
  },
  watch: {
    async '$route'() {
      try {
        if (await userIsAdmin() === 'True') {
          this.isAdmin = true;
        }
        const isValid = await validateToken();
        if (isValid) {
          this.isAuthenticated = true;
        }
      } catch (error) {
        this.isAuthenticated = false;
        this.isAdmin = false;
      }
    }
  },
  mounted() {
    
  },
  methods: {
    async logout() {
      try {
        var response = await post('/Auth/Logout');
        if (response.statusCode === 200) {
          this.isAuthenticated = false;
          this.isAdmin = false;
          this.$router.push('/login');
        }
      } catch (error) {
        console.log(error);
        this.isAuthenticated = false;
        this.isAdmin = false;
      }
    },
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
    }
  }
}
</script>

<style scoped>

.disturbance-brand {
  color:#eeeeee;
  text-align: center;
  font-size: 24px;
  white-space: nowrap;
}

.circle {
  background-color: #221;
  padding: 8px;
  border-radius: 10px;
  font-size: 30px;
}

.circle:hover {
  background-color: #000;
  color: #ccc;
}

.expand {
  display: none;
}

.h4 {
  font-weight: bold;
}

.link-container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  margin-left: 10px;
}

.links {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.links-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.link-text {
  font-size: 16px;
  font-weight: bold;
  /*text-transform: uppercase;*/
  white-space: nowrap;
}

.navbar-disturbance {
  background-image: linear-gradient(to bottom, #223, #445);
  display: grid;
  grid-gap: 30px;
  grid-template-columns: auto auto 1fr auto;
  align-items: center;
  background-color: #333;
  color: #fff;
  padding: 5px 0 5px 10px;
  margin-bottom: 20px;
  min-height: 60px;
  z-index: 1000;
  position: relative;
  border-width: 0 0 1px;
  overflow: hidden;
}

.nav-link {
  display: block;
  text-decoration: none;
  color: inherit;
}

.navigation-link {
  margin: 2px 15px;
  padding: 2px 10px;
}

.navigation-link-border {
  border-left: black solid 2px;
  border-right: black solid 2px;
}

.nav-item:hover {
  /*color: black;
  background-color: black;*/
}

#rftlogo {
  display:inline-block;
  vertical-align:middle;
  width: 121px;
  height: 50px;
  margin-left: 20px;
}

.symbol-nav {
  margin-top: -4px;
}

.warning {
  color: red;
  outline-width: 0;
  margin-top: -2px;
  font-size: 28px;
}

.warning-container {
  margin-top: 15px;
}

@media (max-width: 1200px) {
  .collapsed {
    display: none;
  }
  
  .expand {
    display: flex;
    justify-content: flex-end;
    margin-right: 20px;
  }

  .navbar-disturbance {
    grid-template-columns: 150px auto 1fr;
  }

  .navigation-link {
    margin: 0 10px 0 15px;
  }
  
  .link-container {
    grid-template-columns: 1000px;
    grid-gap: 25px;
  }

  .links {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }

  .links-end {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    margin-bottom: 10px;
  }
}

</style>