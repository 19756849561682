<template>
  <div v-for="disturbance in disturbances" :key="disturbance.disturbanceId" class="disturbance-container">
    <div class="table-default container-fluid">
      <div :class="borderClass(disturbance)">
        <div class="table-head symbols-container">
          <span class="material-symbols-outlined text-red head-symbol-small">warning</span>
          <span v-if="type !== 'history'">{{ disturbance.type }} von {{ formattedMedium(disturbance.medium) }} in {{ formatList(disturbance.region) }}</span>
          <span v-if="type === 'history'">{{ disturbance.status }}</span>
        </div>
        <table class="table">
          <tbody>
          <tr v-if="disturbance.medium">
            <td>Betroffener Dienst</td>
            <td>{{ formatList(disturbance.medium) }}</td>
          </tr>
          <tr v-if="disturbance.type">
            <td>Betroffene Haushalte</td>
            <td v-if="disturbance.type==='Mini-Störung'">&lt;150</td>
            <td v-if="disturbance.type==='Klein-Störung'">150-500</td>
            <td v-if="disturbance.type==='Mittlere Störung'">über 500</td>
            <td v-if="disturbance.type==='Große Störung'">über 4000</td>
          </tr>
          <tr v-if="disturbance.region">
            <td>Betroffene Region/en</td>
            <td>{{ formatList(disturbance.region) }}</td>
          </tr>
          <tr v-if="disturbance.subregion">
            <td class="sub-tablerow"><div class="border-left-second-level">Betroffene Stadtteile</div></td>
            <td>{{ formatList(disturbance.subregion) }}</td>
          </tr>
          <tr v-if="disturbance.streets">
            <td class="sub-tablerow"><div class="border-left-second-level">Betroffene Straßen</div></td>
            <td>{{ formatList(disturbance.streets) }}</td>
          </tr>
          <tr v-if="disturbance.ubr">
            <td class="sub-tablerow"><div class="border-left-second-level">Betroffene UBR</div></td>
            <td>{{ formatList(disturbance.ubr) }}</td>
          </tr>
          <tr v-if="disturbance.cluster">
            <td class="sub-tablerow"><div class="border-left-third-level">Betroffene Cluster</div></td>
            <td>{{ formatList(disturbance.cluster) }}</td>
          </tr>
          <tr v-if="disturbance.first_encounter">
            <td>Erstmeldungszeitpunkt</td>
            <td>{{ formatDate(disturbance.first_encounter) }}</td>
          </tr>
          <tr v-if="disturbance.timestamp">
            <td>Aktualisierungszeitpunkt</td>
            <td>{{ formatDate(disturbance.timestamp) }}</td>
          </tr>
          <tr v-if="disturbance.cause">
            <td>Vermutete/Bestätigte Ursache</td>
            <td>{{ disturbance.cause }}</td>
          </tr>
          <tr v-if="disturbance.message">
            <td>Meldungsnachricht</td>
            <td>{{ disturbance.message }}</td>
          </tr>
          <tr v-if="disturbance.description">
            <td>Beschreibung/Anmerkungen</td>
            <td>{{ disturbance.description }}</td>
          </tr>
          <tr v-if="disturbance.estimated_end">
            <td>Voraussichtliches Ende</td>
            <td>{{ formatDate(disturbance.estimated_end) }}</td>
          </tr>
          <tr v-if="disturbance.mail_sent_to">
            <td>E-Mail versendet an</td>
            <td>{{ disturbance.mail_sent_to.join(', ') }}</td>
          </tr>
          </tbody>
        </table>
        <div class="button-container">
          <div class="grid4x1fr">
            <div class="image-container">
              <img v-if="disturbance.medium.includes('Telefon')||disturbance.medium.includes('Gesamtausfall')" class="medium-image" src="../../src/assets/img/phone.png" alt="#">
              <img v-if="disturbance.medium.includes('Internet')||disturbance.medium.includes('Gesamtausfall')" class="medium-image" src="../../src/assets/img/internet.png" alt="#">
              <img v-if="disturbance.medium.includes('TV')||disturbance.medium.includes('Gesamtausfall')||disturbance.medium.includes('Wunschfernsehen')" class="medium-image" src="../../src/assets/img/tv.png" alt="#">
            </div>
            <div v-if="type !== 'history' && !disturbance.solved && !isEvaluation" class="form-group">
              <button @click="$emit('edit', disturbance.disturbance_id)" class="btn btn-blue btn-issue">Zwischenmeldung</button>
            </div>
            <div v-if="type !== 'history' && !disturbance.solved && !isEvaluation" class="form-group">
              <button @click="$emit('clear', disturbance.disturbance_id)" class="btn btn-green btn-issue">Freimeldung</button>
            </div>
            <div v-if="type !== 'history'" class="form-group">
              <button @click="$emit('history', disturbance.disturbance_id)" class="btn btn-default btn-issue">Störungsverlauf</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    disturbances: {
      type: Array,
      required: true
    },
    type: {
      type: String,
      default: ''
    },
    isEvaluation: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    borderClass(disturbance) {
      if (disturbance.medium.includes('Internet')) {
        return 'issue-container border-green';
      } else if (disturbance.medium.includes('Telefon')) {
        return 'issue-container border-orange';
      } else {
        return 'issue-container border-blue';
      }
    },
    formattedMedium(medium) {
      if (medium.includes('Gesamtausfall')) {
        return 'allen Diensten';
      } else {
        return medium.join(', ').replace(/,(?=[^,]*$)/, ' und');
      }
    },
    formatList(list) {
      return list.join(', ').replace(/,(?=[^,]*$)/, ' und');
    },
    formatDate(date) {
      const dateObj = new Date(date);
      return `${String(dateObj.getDate()).padStart(2, '0')}.${String(dateObj.getMonth() + 1).padStart(2, '0')}.${dateObj.getFullYear()} ${String(dateObj.getHours()).padStart(2, '0')}:${String(dateObj.getMinutes()).padStart(2, '0')}`;
    }
  }
};
</script>

<style scoped>

</style>