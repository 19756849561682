<template>
  <div class="container">
    <div class="add-issue-container">
      <div class="symbols-container heading-box">
        <span class="material-symbols-outlined head-symbol text-red">warning</span>
        <span class="heading-disturbance" v-if="task==='edit'">Störung melden - Zwischenmeldung</span>
        <span class="heading-disturbance" v-if="task==='add'">Störung melden - Erstmeldung</span>
        <span class="heading-disturbance" v-if="task==='clear'">Störung freimelden</span>
      </div>
      <div id="add-issue">
        <div v-if="successMessage">
          <span class="text-green">{{successMessage}}</span>
        </div>
        <div v-else-if="errorMessage">
          <span class="text-red">{{errorMessage}}</span>
        </div>
        <div class="form-info-container">
          <div class="symbols-container">
            <span class="material-symbols-outlined label-symbol">checklist_rtl</span><span class="label-symbol">- Mehrfachauswahl möglich</span>
          </div>
          <div class="symbols-container">
            <span class="text-red label-symbol">*</span><span class="label-symbol"> &emsp;- sind Pflichtfelder</span>
          </div>
        </div>
        <div class="form-group">
          <div class="symbols-container">
            <label for="issue">Betroffener Dienst:</label><span class="text-red label-symbol">*</span><span class="material-symbols-outlined label-symbol">checklist_rtl</span>
          </div>
          <Multiselect v-if="task!=='clear'"
              v-model="selectedIssues"
              mode="tags"
              :options="computedIssues"
              :groups="true"
              :groupSelect="false"
              max="3"
              id="issue"
          />
          <span v-if="task==='clear'">{{this.selectedIssues.join(', ').replace(/,(?=[^,]*$)/, ' und')}}</span>
        </div>

        <div class="form-group">
          <div class="symbols-container">
            <label for="type">Kategorie:</label><span class="text-red label-symbol">*</span>
          </div>
          <select v-if="task!=='clear'" class="form-control dropdown" required="required" name="type" id="type" v-model="selectedType">
            <option selected disabled hidden value="">Auswählen</option>
            <option value="Mini-Störung">Mini-Störung (&lt;150 betroffene Kunden)</option>
            <option value="Klein-Störung">Klein-Störung (150-500 betroffene Kunden)</option>
            <option value="Mittlere Störung">Mittlere Störung (ab 500 betroffene Kunden)</option>
            <option value="Große Störung">Große Störung (über 4000 betroffene Kunden)</option>
          </select>
          <span v-if="task==='clear'">{{this.selectedType}}</span>
        </div>

        <div class="form-group">
          <label for="cause"><span v-if="task==='add'">Vermutete Ursache:</span><span v-if="task!=='add'">Ursache:</span></label>
          <Multiselect
              v-model="selectedCause"
              mode="single"
              :searchable="true"
              :options="causes"
              :createOption="true"
              :appendNewOption="false"
              :show-labels="false"
              :label="'property'"
              :valueProp="'property'"
              :trackBy="'property'"
              :searchStart="true"
              :noOptionsText="'keine Optionen vorhanden'"
              :noResultsText="'keine Ergebnis'"
              id="cause"
          />
        </div>
        
        <div v-if="task==='add'||task==='edit'" class="form-group">
          <div class="symbols-container">
            <label for="disturbance-message">Störungsnachricht:</label><span class="material-symbols-outlined label-symbol">checklist_rtl</span>
          </div>
          <Multiselect
              v-model="selectedMessages"
              mode="tags"
              :searchable="true"
              :options="messages"
              :show-labels="false"
              :groupSelect="true"
              :createOption="true"
              :appendNewOption="false"
              :label="'property'"
              :valueProp="'property'"
              :trackBy="'property'"
              :noOptionsText="'keine Optionen vorhanden'"
              :noResultsText="'keine Ergebnis'"
              id="disturbance-message"
          />
        </div>

        <div class="form-group">
          <div class="symbols-container">
            <label for="place">Betroffene Regionen:</label><span class="text-red label-symbol">*</span><span class="material-symbols-outlined label-symbol">checklist_rtl</span>
          </div>
          <Multiselect v-if="task!=='clear'"
              v-model="selectedRegion"
              mode="tags"
              :searchable="true"
              :options="regions"
              :show-labels="false"
              :createOption="true"
              :appendNewOption="false"
              :label="'property'"
              :valueProp="'property'"
              :trackBy="'property'"
              :searchStart="true"
              :noOptionsText="'keine Optionen vorhanden'"
              :noResultsText="'keine Ergebnis'"
              id="place"
              @input="clearElementsRegion"
          />
          <span v-if="task==='clear'">{{this.selectedRegion.join(', ').replace(/,(?=[^,]*$)/, ' und')}}</span>
        </div>
        
        <div v-if="task!=='clear'" class="sub-properties">
          <div v-if="filteredSubregions.length > 0" class="form-group">
            <div class="symbols-container">
              <label class="text-grey" for="subregion">Stadtteil:</label><span class="material-symbols-outlined label-symbol">checklist_rtl</span>
            </div>
            <Multiselect
                v-model="selectedSubregion"
                mode="tags"
                :searchable="true"
                :options="filteredSubregions"
                :show-labels="false"
                :createOption="true"
                :appendNewOption="false"
                :label="'property'"
                :valueProp="'property'"
                :trackBy="'property'"
                :searchStart="true"
                :noOptionsText="'keine Optionen vorhanden'"
                :noResultsText="'keine Ergebnis'"
                id="subregion"
            />
          </div>

          <div v-if="filteredStreets.length > 0" class="form-group">
            <div class="symbols-container">
              <label class="text-grey" for="street">Straße:</label><span class="material-symbols-outlined label-symbol">checklist_rtl</span>
            </div>
            <Multiselect
                v-model="selectedStreets"
                mode="tags"
                :searchable="true"
                :options="filteredStreets"
                :show-labels="false"
                :createOption="true"
                :appendNewOption="false"
                :label="'property'"
                :valueProp="'property'"
                :trackBy="'property'"
                :searchStart="false"
                :noOptionsText="'keine Optionen vorhanden'"
                :noResultsText="'keine Ergebnis'"
                id="streets"
            />
          </div>

          <div v-if="filteredUbr.length > 0" class="form-group">
            <div class="symbols-container">
              <label class="text-grey" for="subregion">UBR (intern):</label><span class="material-symbols-outlined label-symbol">checklist_rtl</span>
            </div>
            <Multiselect
                v-model="selectedUbr"
                mode="tags"
                :searchable="true"
                :options="filteredUbr"
                :show-labels="false"
                :createOption="true"
                :appendNewOption="false"
                :label="'property'"
                :valueProp="'property'"
                :trackBy="'property'"
                :searchStart="false"
                :noOptionsText="'keine Optionen vorhanden'"
                :noResultsText="'keine Ergebnis'"
                id="ubr"
                @input="clearElementsUbr"
            />
          </div>

          <div class="sub-properties">
            <div v-if="filteredCluster.length > 0 && selectedUbr.length > 0" class="form-group">
              <div class="symbols-container">
                <label class="text-grey" for="cluster">Cluster (intern):</label><span class="material-symbols-outlined label-symbol">checklist_rtl</span>
              </div>
              <Multiselect
                  v-model="selectedCluster"
                  mode="tags"
                  :searchable="true"
                  :options="filteredCluster"
                  :show-labels="false"
                  :createOption="true"
                  :appendNewOption="false"
                  :label="'property'"
                  :valueProp="'property'"
                  :trackBy="'property'"
                  :searchStart="false"
                  :noOptionsText="'keine Optionen vorhanden'"
                  :noResultsText="'keine Ergebnis'"
                  id="cluster"
              />
            </div>
          </div>
        </div>

        <div v-if="task==='add'||task==='edit'" class="form-group">
          <label for="description">Zusätzliche Beschreibung/Anmerkungen (intern):</label>
          <textarea class="form-control" name="description" id="description" rows="3" v-model="description"></textarea>
        </div>

        <div v-if="task==='clear'" class="form-group">
          <label for="clear-comment">Freimeldungskommentar:</label>
          <input type="text" class="form-control" name="clear-comment" id="clear-comment" v-model="clearComment">
        </div>
        
        <div v-if="task==='add'" class="form-group">
          <label for="first-encounter">Erstmeldungszeitpunkt:</label>
          <input type="datetime-local" class="form-control " required="required" name="first-encounter" id="first-encounter" v-model="currentDate">
        </div>
        <div v-if="task==='add'||task==='edit'" class="form-group-checkbox">
          <input type="checkbox" name="use-table" id="use-table" v-model="uploadToHomepage">
          <label class="checkbox-text" for="use-table">Störungsmeldung auf der RFT Kabel Website anzeigen</label>
        </div>
        <div v-if="task==='edit'" class="form-group">
          <label for="estimated-end-date">Ende absehbar bis:</label>
          <div class="grid2x1fr">
            <div class="form-group">
              <label for="estimated-end-date">Tag</label>
              <input type="date" class="form-control" name="estimated-end-date" id="estimated-end-date" v-model="estimatedEndDate">
            </div>
            <div class="form-group">
              <label for="estimated-end-time">Uhrzeit</label>
              <input type="time" class="form-control" name="estimated-end-time" id="estimated-end-time" v-model="estimatedEndTime">
            </div>
          </div>
        </div>
        <!--div class="form-group">
          <label for="disturbance-message"></label>
          <Multiselect
              v-model="selectedInterna"
              mode="tags"
              :searchable="true"
              :options="interna"
              :show-labels="false"
              :groupSelect="true"
              :createOption="true"
              :appendNewOption="false"
              :label="'property'"
              :valueProp="'property'"
              :trackBy="'property'"
              :searchStart="true"
              :noOptionsText="'keine Optionen vorhanden'"
              :noResultsText="'keine Ergebnis'"
              id="disturbance-message"
          />
        </div-->
        <div class="grid2x1fr">
          <div class="form-group">
            <button  v-if="task!=='add'" @click="goBack" type="submit" class="btn btn-default">Zurück</button>
          </div>
          <div class="flex-right-container">
            <div v-if="task==='add'" class="form-group">
              <button @click="addDisturbance" type="submit" class="btn btn-green">Bestätigen</button>
            </div>
            <div v-if="task==='edit'" class="form-group">
              <button @click="editDisturbance" type="submit" class="btn btn-green">Bestätigen</button>
            </div>
            <div v-if="task==='clear'" class="form-group">
              <button @click="clearDisturbance" type="submit" class="btn btn-green">Bestätigen</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {post, get} from '../api.js';
import Multiselect from '@vueform/multiselect';
import {getUserName} from './LoginScreen.vue';
export default {
  name: "DisturbanceForm",
  components: {
    Multiselect,
  },
  props: {
    id: {
      type: Number,
      default: null
    },
    task: {
      type: String,
      default: "add"
    },
  },
  data() {
    return {
      userName: '',
      successMessage: '',
      errorMessage: '',
      issues: [{label:' ', options:['Internet','Telefon','TV','RFT choice', 'Wunschfernsehen']},{label:'', options:['Gesamtausfall']}],
      regions: [],
      subregions: [],
      streets: [],
      cluster: [],
      ubr: [],
      causes: [],
      messages: [],
      selectedMessages: [],
      selectedRegion: [],
      selectedSubregion: [],
      selectedStreets: [],
      selectedCluster: [],
      selectedUbr: [],
      selectedCause: '',
      selectedIssues: [],
      selectedType: '',
      selectedFirstEncounter: '',
      currentDate: '',
      estimatedEndDate: '',
      estimatedEndTime: '',
      description: '',
      clearComment: '',
      responseMessage: '',
      responseRegion: '',
      responseSubregion: '',
      responseStreets: '',
      responseCluster: '',
      responseUbr: '',
      plentaId: '',
      uploadToHomepage: true
    };
  },
  async created() {
    Promise.all([this.getAllProperties()]).then(() => {
      this.setSelectedProperties()
    });
    this.userName = await getUserName();
  },
  computed: {
    filteredSubregions() {
      if (Array.isArray(this.selectedRegion) && this.selectedRegion.length > 0) {
        const ids = this.selectedRegion.map(region => {
          const foundRegion = this.regions.find(r => r.property === region);
          return foundRegion ? foundRegion.propertyid : null;
        });
        return this.subregions.filter(subregion => ids.includes(subregion.toplevelproperty));
      }
      return [];
      /*const foundRegion = this.regions.find(region => region.property === this.selectedRegion);
      if (foundRegion !== undefined) {
        const id = foundRegion.propertyid;
        return this.subregions.filter(subregion => subregion.toplevelproperty === id);
      }
      return [];*/
    },
    filteredStreets() {
      if (Array.isArray(this.selectedRegion) && this.selectedRegion.length > 0) {
        const ids = this.selectedRegion.map(region => {
          const foundRegion = this.regions.find(r => r.property === region);
          return foundRegion ? foundRegion.propertyid : null;
        });
        return this.streets.filter(street => ids.includes(street.toplevelproperty));
      }
      return [];
    },
    filteredUbr() {
      if (Array.isArray(this.selectedRegion) && this.selectedRegion.length > 0) {
        const ids = this.selectedRegion.map(region => {
          const foundRegion = this.regions.find(r => r.property === region);
          return foundRegion ? foundRegion.propertyid : null;
        });
        return this.ubr
            .filter((ubr) => ids.includes(ubr.toplevelproperty))
            .reduce((acc, current) => {
              if (!acc.seen.has(current.property)) {
                acc.seen.add(current.property);
                acc.result.push(current);
              }
              return acc;
            }, { seen: new Set(), result: [] }).result;
      }
      return [];
    },
    filteredCluster() {
      if (Array.isArray(this.selectedUbr) && this.selectedUbr.length > 0) {
        const ids = this.selectedUbr.map(ubr => {
          const foundUbr = this.filteredUbr.find(r => r.property === ubr);
          return foundUbr ? foundUbr.propertyid : null;
        });
        return this.cluster.filter(cluster => ids.includes(cluster.toplevelproperty));
      }
      return [];
    },
    computedIssues() {
      const selectedGroup = this.issues.find(group => this.selectedIssues.some(issue => group.options.includes(issue)));
      return selectedGroup ? this.issues.filter(group => group.label === selectedGroup.label) : this.issues;
    }
  },
  mounted() {
    this.setCurrentDateTime();
  },
  watch: {
    id: {
      immediate: true,
      handler(newId) {
        if (newId&&newId>0) {
          this.getDisturbanceById(newId);
        }
      }
    },
    '$route.params.id': function(newVal, oldVal) {
      if (newVal !== oldVal) {
        window.location.reload()
      }
    }
  },
  methods: {
    clearElementsRegion() {
      this.selectedSubregion = [];
      this.selectedCluster = [];
      this.selectedUbr = [];
      this.selectedStreets = [];
    },
    clearElementsUbr() {
      this.selectedCluster = [];
    },
    setSelectedProperties() {
      for (const message of this.messages) {
        if (this.responseMessage !== null && this.responseMessage.includes(message.property)) {
          this.selectedMessages.push(message.property);
        }
      }
      let selectedRegionIds = [];
      if (this.task !== 'clear') {
        for (const region of this.regions) {
          if (this.responseRegion !== null && this.responseRegion.includes(region.property)) {
            this.selectedRegion.push(region.property);
            selectedRegionIds.push(region.propertyId);
          }
        }
      } else {
        this.selectedRegion = JSON.parse(this.responseRegion);
      }
      for (const street of this.streets) {
        if (this.responseStreets !== null && this.responseStreets.includes(street.property) && selectedRegionIds.includes(street.topLevelProperty)) {
          this.selectedStreets.push(street.property);
        }
      }
      this.selectedStreets = [...new Set(this.selectedStreets)];
      for (const subregion of this.subregions) {
        if (this.responseSubregion !== null && this.responseSubregion.includes(subregion.property)) {
          this.selectedSubregion.push(subregion.property);
        }
      }
      for (const ubr of this.ubr) {
        if (this.responseUbr !== null && this.responseUbr.includes(ubr.property)) {
          this.selectedUbr.push(ubr.property);
        }
      }
      this.selectedUbr = [...new Set(this.selectedUbr)];
      for (const cluster of this.cluster) {
        if (this.responseCluster !== null && this.responseCluster.includes(cluster.property)) {
          this.selectedCluster.push(cluster.property);
        }
      }
      this.selectedCluster = [...new Set(this.selectedCluster)];
    },
    setCurrentDateTime() {
      const now = new Date();
      const currectDateInCET = new Date(now.toLocaleString("en-US", { timeZone: "Europe/Berlin" }));
      const year = currectDateInCET.getFullYear();
      const month = String(currectDateInCET.getMonth() + 1).padStart(2, '0');
      const day = String(currectDateInCET.getDate()).padStart(2, '0');
      const hours = String(currectDateInCET.getHours()).padStart(2, '0');
      const minutes = String(currectDateInCET.getMinutes()).padStart(2, '0');
      const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}`;
      this.currentDate = formattedDate;
    },
    async getDisturbanceById(disturbanceId){
      try {
        var response = await get('/Disturbance/GetDisturbance?id='+disturbanceId);
        if (!response.data || response.data.length < 1 || typeof response.data[0] === 'undefined') {
          this.$router.push({ path: '/disturbance-editor/add/-2' }).catch(() => {});
        }
        if (response.data[0].solved) {
          this.$router.push({ path: '/disturbance-editor/add/-2' }).catch(() => {}); 
        }
        const responseIssue = response.data[0].medium;
        this.issues.forEach(issue => {
          issue.options.forEach(option => {
            if (responseIssue.includes(option)) {
              this.selectedIssues.push(option);
            }
          });
        });
        this.selectedCause = response.data[0].cause;
        this.responseMessage = response.data[0].message;
        this.responseRegion = response.data[0].region;
        this.responseSubregion = response.data[0].subregion;
        this.responseStreets = response.data[0].streets;
        this.responseCluster = response.data[0].cluster;
        this.responseUbr = response.data[0].ubr;
        this.selectedType = response.data[0].type;
        this.description = response.data[0].description;
        this.selectedFirstEncounter = response.data[0].first_encounter;
        this.plentaId = response.data[0].plenta_id;
      } catch (error) {
        console.log(error);
      }
    },
    async getAllProperties(){
      try {
        var priority = null;
        if (this.task === 'add')
          priority = 1;
        else if (this.task === 'edit')
          priority = 2;
        var messageResponse;
        if(priority !== null)
          messageResponse = await get('/Disturbance/GetProperties?propertyType=message&priority='+priority);
        else
          messageResponse = await get('/Disturbance/GetProperties?propertyType=message');
        this.messages = messageResponse.data;
        var regionResponse = await get('/Disturbance/GetProperties?propertyType=region');
        this.regions = regionResponse.data;
        var causeResponse;
        if(priority !== null)
          causeResponse = await get('/Disturbance/GetProperties?propertyType=cause&priority='+priority);
        else
          causeResponse = await get('/Disturbance/GetProperties?propertyType=cause');
        this.causes = causeResponse.data;
        var subregionResponse = await get('/Disturbance/GetProperties?propertyType=subregion');
        this.subregions = subregionResponse.data;
        var streetResponse = await get('/Disturbance/GetProperties?propertyType=street');
        this.streets = streetResponse.data;
        var clusterResponse = await get('/Disturbance/GetProperties?propertyType=cluster');
        this.cluster = clusterResponse.data;
        var ubrResponse = await get('/Disturbance/GetProperties?propertyType=ubr');
        this.ubr = ubrResponse.data;
      } catch (error) {
        console.log(error);
      }
    },
    async addDisturbance(){
      try {
        if (this.selectedIssues.length < 1 || this.selectedType === '' || this.selectedRegion.length < 1) {
          throw new Error("Nicht alle Pflichtfelder wurden ausgefüllt")
        }
          
        var parameters = {
          Medium: "[" + this.selectedIssues.map(item => `"${item}"`).join(', ') + "]",
          Type: this.selectedType,
          FirstEncounter: this.currentDate,
          Region: "[" + this.selectedRegion.map(item => `"${item}"`).join(', ') + "]",
        };
        if (this.selectedCause !== '')
          parameters.cause = this.selectedCause;
        if (this.description !== '')
          parameters.description = this.description;
        if (this.selectedSubregion.length > 0)
          parameters.subregion = "[" + this.selectedSubregion.map(item => `"${item}"`).join(', ') + "]";
        if (this.selectedStreets.length > 0)
          parameters.streets = "[" + this.selectedStreets.map(item => `"${item}"`).join(', ') + "]";
        if (this.selectedCluster.length > 0)
          parameters.cluster = "[" + this.selectedCluster.map(item => `"${item}"`).join(', ') + "]";
        if (this.selectedUbr.length > 0)
          parameters.ubr = "[" + this.selectedUbr.map(item => `"${item}"`).join(', ') + "]";
        if (this.selectedMessages.length > 0)
          parameters.Message = this.selectedMessages.join(' ');
        var response = await post('/Disturbance/AddDisturbance?username='+this.userName+'&uploadToHomepage='+this.uploadToHomepage, parameters);
        console.log(response.message);
        if (response.status === response.ok) {
          this.$router.push({ path: '/overview' }).catch(() => {});
        } else {
          this.errorMessage = "Ein Fehler ist aufgetreten";
        }
      } catch (error) {
        console.log(error);
        this.errorMessage = error.message;
      }
    },
    async editDisturbance(){
      try {
        if (this.selectedIssues.length < 1 || this.selectedType === '' || this.selectedRegion.length < 1) {
          throw new Error("Nicht alle Pflichtfelder wurden ausgefüllt")
        }
        let dateValue = null;
        if (this.estimatedEndDate !== '')
        {
          dateValue = this.estimatedEndDate;
          if (this.estimatedEndTime !== '') {
            dateValue = dateValue + "T" + this.estimatedEndTime;
          } else {
            dateValue = dateValue + "T00:00:00.000Z";
          }
        }
        var parameters = {
          Medium: "[" + this.selectedIssues.map(item => `"${item}"`).join(', ') + "]",
          Type: this.selectedType,
          FirstEncounter: this.selectedFirstEncounter,
          EstimatedEnd: dateValue,
          Region: "[" + this.selectedRegion.map(item => `"${item}"`).join(', ') + "]"
        };
        if (this.selectedCause !== '')
          parameters.cause = this.selectedCause;
        if (this.description !== '')
          parameters.description = this.description;
        if (this.selectedSubregion.length > 0)
          parameters.subregion = "[" + this.selectedSubregion.map(item => `"${item}"`).join(', ') + "]";
        if (this.selectedStreets.length > 0)
          parameters.streets = "[" + this.selectedStreets.map(item => `"${item}"`).join(', ') + "]";
        if (this.selectedCluster.length > 0)
          parameters.cluster = "[" + this.selectedCluster.map(item => `"${item}"`).join(', ') + "]";
        if (this.selectedUbr.length > 0)
          parameters.ubr = "[" + this.selectedUbr.map(item => `"${item}"`).join(', ') + "]";
        if (this.selectedMessages.length > 0)
          parameters.Message = this.selectedMessages.map(item => `"${item}"`).join(' ');
        var response = await post('/Disturbance/UpdateDisturbance?username='+this.userName+'&disturbanceId='+this.id+'&uploadToHomepage='+this.uploadToHomepage+'&plentaId='+this.plentaId, parameters);
        console.log(response.message);
        if (response.status === response.ok) {
          this.$router.push({ path: '/overview' }).catch(() => {});
        } else {
          this.errorMessage = "Ein Fehler ist aufgetreten";
        }
      } catch (error) {
        console.log(error);
        this.errorMessage = error.message;
      }
    },
    async clearDisturbance(){
      try {
        if (this.selectedIssues.length < 1 || this.selectedType === '' || this.selectedRegion.length < 1) {
          throw new Error("Nicht alle Pflichtfelder wurden ausgefüllt")
        }
        
        var parameters = {
          Medium: "[" + this.selectedIssues.map(item => `"${item}"`).join(', ') + "]",
          Cause: this.selectedCause,
          Type: this.selectedType,
          FirstEncounter: this.selectedFirstEncounter,
          Region: this.responseRegion,
          Message: this.selectedMessages.join(' ')
        };
        if (this.description !== '')
          parameters.description = this.description;
        parameters.subregion = this.responseSubregion;
        //parameters.streets = "[" + this.responseStreets.map(item => `"${item}"`).join(', ') + "]";
        parameters.streets = this.responseStreets;
        parameters.cluster = this.responseCluster;
        parameters.ubr = this.responseUbr;
        if (this.clearComment !== '')
          parameters.clearComment = this.clearComment;
        var response = await post('/Disturbance/ClearDisturbance?username='+this.userName+'&disturbanceId='+this.id+'&plentaId='+this.plentaId, parameters);
        console.log(response.message);
        if (response.status === response.ok) {
          this.$router.push({ path: '/overview' }).catch(() => {});
        } else {
          this.errorMessage = "Ein Fehler ist aufgetreten";
        }
      } catch (error) {
        console.log(error);
        this.errorMessage = error.message;
      }
    },
    goBack() {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>
@import "../assets/styles/default.css";
@import "../assets/styles/add_issue.css";


</style>