<template>
  <div class="overview-container">
    <div class="overview-head">
      <h2 class="overview-title">Meldung</h2>
      <div class="grid2x1fr-notfix">
        <div class="grid2x1fr-fix internal-form-group">
          <label class="form-label" for="issue">Interne Störung/Wartung:</label>
          <select class="form-control dropdown" required="required" v-model="type">
            <option selected disabled hidden value="">Auswählen</option>
            <option value="disturbance">Störung</option>
            <option value="maintenance">Wartung</option>
          </select>
        </div>
      </div>
      <div v-if="type==='disturbance'" class="grid2x1fr-notfix">
        <div class="grid2x1fr-fix internal-form-group">
          <label class="form-label" for="issue">Betroffener Dienst:</label>
          <Multiselect v-model="selectedServices"
                       mode="tags"
                       :options="services"
                       :createOption="true"
                       :appendNewOption="false"
                       :searchable="true"
          />
        </div>
        <div class="grid2x1fr-fix internal-form-group">
          <label class="form-label" for="issue">Hält voraussichtlich an bis:</label>
          <input type="datetime-local" class="form-control" v-model="internalDisturbanceTime">
        </div>
        <div class="grid2x1fr-fix internal-form-group">
          <label class="form-label" for="issue">Externe Kunden informieren?:</label>
          <input type="checkbox" name="use-table" id="use-table" v-model="informCustomers">
        </div>
        <div class="grid2x1fr-fix internal-form-group">
          <label class="form-label" for="issue">Kundencenter/Kundenbüros betroffen?:</label>
          <Multiselect v-model="customerCenter"
                       mode="tags"
                       :options="customerCenterList"
                       :createOption="true"
                       :appendNewOption="false"
                       :searchable="true"
          />
        </div>
        <div class="grid2x1fr-fix internal-form-group">
          <label class="form-label" for="issue">Firmen betroffen?:</label>
          <Multiselect v-model="companies"
                       mode="tags"
                       :options="companyList"
                       :createOption="true"
                       :appendNewOption="false"
                       :searchable="true"
          />
        </div>
        <div class="grid2x1fr-fix internal-form-group">
          <label class="form-label" for="issue">Mail zusätzlich an:</label>
          <Multiselect v-model="additionalMails"
                       mode="tags"
                       :options="additionalMailList"
                       :createOption="true"
                       :appendNewOption="false"
                       :searchable="true"
          />
        </div>
      </div>
      <div v-if="type==='maintenance'">
        <div class="grid2x1fr-notfix">
          <div>
            <div class="grid2x1fr-fix internal-form-group">
              <label class="form-label" for="issue">Von:</label>
              <input type="datetime-local" class="form-control" v-model="internalMaintenanceStart">
            </div>
            <div class="grid2x1fr-fix internal-form-group">
              <label class="form-label" for="issue">Bis:</label>
              <input type="datetime-local" class="form-control" v-model="internalMaintenanceEnd">
            </div>
          </div>
          <div>
            <div class="grid2x1fr-fix internal-form-group">
              <label class="form-label" for="issue">Was wird gewartet:</label>
              <input type="text" class="form-control" v-model="service">
            </div>
            <div class="grid2x1fr-fix internal-form-group">
              <label class="form-label" for="issue">Externe Kunden informieren?:</label>
              <input type="checkbox" name="use-table" id="use-table" v-model="informCustomers">
            </div>
          </div>
        </div>
      </div>
      <div v-if="type!==''" class="grid1fr3fr internal-form-group form-large">
        <label class="form-label" for="issue">Weitere Informationen:</label>
        <input type="text" class="form-control" v-model="infos">
      </div>
      <div class="flex-right-container">
        <div v-if="type==='disturbance'" class="form-group">
          <button @click="addInternalDisturbance" type="submit" class="btn btn-green">Bestätigen</button>
        </div>
        <div v-if="type==='maintenance'" class="form-group">
          <button @click="addInternalMaintenance" type="submit" class="btn btn-green">Bestätigen</button>
        </div>
      </div>
    </div>
    <h1 class="overview-title">Übersicht</h1>
    <div class="grid2x1fr">
      <div>
        <h2 class="overview-title">Interne Störungen</h2>
        <div v-for="disturbance in disturbances" :key="disturbance.internalDisturbanceId" class="disturbance-container">
          <div class="table-default container-fluid">
            <div class="issue-container">
              <table class="table">
                <tbody>
                <tr>
                  <td>Dienst/Anwendung</td>
                  <td>{{ formatList(disturbance.service) }}</td>
                </tr>
                <tr>
                  <td>Voraussichtliches Ende</td>
                  <td>{{ formatDate(disturbance.estimatedend) }}</td>
                </tr>
                <tr>
                  <td>Ersteller</td>
                  <td>{{ disturbance.displayname }}</td>
                </tr>
                </tbody>
              </table>
              <div class="button-container">
                <div class="grid4x1fr">
                  <div class="form-group">
                    <button @click="clearInternalDisturbance(disturbance)" class="btn btn-green btn-issue">Freimeldung</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <h2 class="overview-title">Interne Wartungen</h2>
        <div v-for="maintenance in maintenances" :key="maintenance.internalMaintenanceId" class="disturbance-container">
          <div class="table-default container-fluid">
            <div class="issue-container">
              <table class="table">
                <tbody>
                <tr>
                  <td>Was wird gewartet</td>
                   <td>{{ maintenance.service }}</td>
                </tr>
                <tr>
                  <td>Start</td>
                  <td>{{ formatDate(maintenance.start) }}</td>
                </tr>
                <tr>
                  <td>Ende</td>
                  <td>{{ formatDate(maintenance.end) }}</td>
                </tr>
                <tr>
                  <td>Externe Kunden informieren?</td>
                  <td>{{ maintenance.informcustomers ? 'Ja' : 'Nein' }}</td>
                </tr>
                <tr>
                  <td>Ersteller</td>
                  <td>{{ maintenance.displayname }}</td>
                </tr>
                <tr>
                  <td>Weitere Informationen</td>
                  <td>{{ maintenance.infos }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import {post, get} from '../api.js';
import Multiselect from '@vueform/multiselect';
import {getUserName} from './LoginScreen.vue';
export default {
  name: "InternalDisturbance",
  components: {
    Multiselect,
  },
  data() {
    return {
      type: '',
      successMessage: '',
      services: ['Interne Telefonie', 'Externe Telefonie', 'Interne und Externe Telefonie', 'BauSU', 'Easy Archiv', 'Firewall', 'Nextcloud', 'Outlook', 'TeilNV', 'Zammad'],
      selectedServices: [],
      internalDisturbanceTime: '',
      internalMaintenanceStart: '',
      internalMaintenanceEnd: '',
      service: '',
      informCustomers: false,
      infos: '',
      disturbances: [],
      maintenances: [],
      userName: '',
      customerCenter: [],
      customerCenterList: ['KC Brandenburg an der Havel', 'KC Potsdam', 'KC Neuruppin', 'KB Premnitz', 'KB Bad Belzig'],
      companies: [],
      companyList: ['RFT Kabel', 'RFT Kabel Nord', 'AGILISCOM', 'Trendhaus', 'RFT elkom'],
      additionalMails: [],
      additionalMailList: ['2nd@xpron.com', 'Rundmail@agiliscom.de', 'mail@inesjohn.de']
    };
  },
  async created() {
    this.userName = await getUserName();
  },
  mounted() {
    this.setCurrentDateTime();
    this.getAllInternalDisturbances();
    this.getAllInternalMaintenances();
  },
  methods: {
    formatList(list) {
      return list.join(', ').replace(/,(?=[^,]*$)/, ' und');
    },
    setCurrentDateTime() {
      const now = new Date();
      const currectDateInCET = new Date(now.toLocaleString("en-US", { timeZone: "Europe/Berlin" }));
      const year = currectDateInCET.getFullYear();
      const month = String(currectDateInCET.getMonth() + 1).padStart(2, '0');
      const day = String(currectDateInCET.getDate()).padStart(2, '0');
      const hours = String(currectDateInCET.getHours()).padStart(2, '0');
      const minutes = String(currectDateInCET.getMinutes()).padStart(2, '0');
      const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}`;
      this.internalDisturbanceTime = formattedDate;
      this.internalMaintenanceStart = formattedDate;
      this.internalMaintenanceEnd = formattedDate;
    },
    async addInternalDisturbance() {
      try {
        let internalDisturbance = {
          Service: "[" + this.selectedServices.map(item => `"${item}"`).join(', ') + "]",
          EstimatedEnd: this.internalDisturbanceTime,
          InformCustomers: this.informCustomers,
          Infos: this.infos
        };
        if (this.customerCenter.length > 0)
          internalDisturbance.customerCenter = "[" + this.customerCenter.map(item => `"${item}"`).join(', ') + "]";
        if (this.companies.length > 0)
          internalDisturbance.companies = "[" + this.companies.map(item => `"${item}"`).join(', ') + "]";
        let additionalMails = '';
        if (this.additionalMails.length > 0)
          additionalMails = "[" + this.additionalMails.map(item => `"${item}"`).join(', ') + "]";
        if (additionalMails.length > 0)
          await post('/Internal/AddInternalDisturbance?username=' + this.userName + '&additionalMails=' + additionalMails, internalDisturbance);
        else
          await post('/Internal/AddInternalDisturbance?username=' + this.userName, internalDisturbance);
        window.location.reload();
      } catch (error) {
        console.log(error);
      }
    },
    async addInternalMaintenance () {
      try {
        let internalMaintenance = {
          Start: this.internalMaintenanceStart,
          End: this.internalMaintenanceEnd,
          Service: this.service,
          InformCustomers: this.informCustomers,
          Infos: this.infos
        };
        await post('/Internal/AddInternalMaintenance?username=' + this.userName, internalMaintenance);
        window.location.reload();
      } catch (error) {
        console.log(error);
      }
    },
    async getAllInternalDisturbances() {
      try {
        let response = await get('/Internal/GetInternalDisturbances');
        this.disturbances = response.data;
        this.disturbances.forEach(item => {
          item.service = JSON.parse(item.service);
        });
      } catch (error) {
        console.log(error);
      }
    },
    async getAllInternalMaintenances() {
      try {
        let response = await get('/Internal/GetInternalMaintenances');
        this.maintenances = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async clearInternalDisturbance(disturbance) {
      try {
        let response = await post('/Internal/ClearInternalDisturbance?internalDisturbanceId='+disturbance.internaldisturbanceid+'&username=' + this.userName);
        await this.getAllInternalDisturbances();
        console.log(response.message);
      } catch (error) {
        console.log(error);
      }
    },
    formatDate(date) {
      const dateObj = new Date(date);
      return `${String(dateObj.getDate()).padStart(2, '0')}.${String(dateObj.getMonth() + 1).padStart(2, '0')}.${dateObj.getFullYear()} ${String(dateObj.getHours()).padStart(2, '0')}:${String(dateObj.getMinutes()).padStart(2, '0')}`;
    }
  }
}
</script>

<style scoped>
@import "../assets/styles/default.css";
@import "../assets/styles/add_issue.css";
@import "../assets/styles/overviews.css";

input[type="checkbox"] {
  margin-top: 9px !important;
}

.form-input-time {
  width: max(50%, 150px);
}

.form-label {
  font-size: 18px;
  margin-top: 5px;
  padding: 0 30px;
  text-align: right;
}

.full-span {
  grid-column: span 3;
}

.grid1fr3fr {
  grid-template-columns: 1fr 3fr;
  display: grid;
}

.grid2x1fr-fix {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.grid2x1fr-notfix {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.internal-form-group {
  margin: 20px 0 20px 0;
}

@media (max-width: 1000px) {
  .grid2x1fr-notfix {
    grid-template-columns: 1fr;
  }

  .grid1fr3fr {
    grid-template-columns: 1fr 1fr;
  }
}
</style>