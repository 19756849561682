<template>
  <div class="overview-container">
    <div class="overview-head">
      <div v-if="this.type==='history'" class="symbols-container return-button" @click=redirectOverview><span class="material-symbols-outlined">Undo</span> <span>Zur Gesamtübersicht</span></div>
    </div>
    <h1 v-if="this.type==='all'" class="display-1 overview-title">Störungsübersicht</h1>
    <div v-if="disturbances.length < 1" class="table-head symbols-container">
      <span>Es liegen keine Störungsmeldungen vor.</span><span class="material-symbols-outlined text-green head-symbol-small">check_circle</span>
    </div>
    <h2 v-if="this.type==='history' && disturbances.length>0" class="display-1 overview-title history-container padding-history">{{ disturbances[0].type }} von {{ formattedMedium(disturbances[0].medium) }} in {{ disturbances[0].region.join(', ').replace(/,(?=[^,]*$)/, ' und') }} vom {{ formatDate(disturbances[0].first_encounter) }}</h2>
    <div :class="gridClass()">
      <DisturbanceTable
          :disturbances="disturbances"
          :type="type"
          @edit="editRedirect"
          @clear="clearRedirect"
          @history="showHistory"
      />
    </div>
  </div>
</template>

<script>
import DisturbanceTable from '@/components/DisturbanceTable.vue';
import {get} from '../api.js';
export default {
  name: "DisturbanceOverview",
  props: {
    id: {
      type: Number,
      default: null
    },
    type: {
      type: String,
      default: "all"
    }
  },
  components: {
    DisturbanceTable
  },
  data() {
    return {
      isSearchBoxVisible: false,
      disturbances: [],
      medium: '',
      region: '',
      beginDate: '',
      endDate: '',
      solved: false,
      reverseOrder: true
    };
  },
  mounted() {
    if (this.type === 'all') {
      this.getDisturbances();
    }
    if (this.type === 'history') {
      this.getDisturbanceWithHistory(this.id)
    }
  },
  watch: {
    type(val){
      if (val === 'all') {
        this.getDisturbances();
      }
      if (val === 'history') {
        this.getDisturbanceWithHistory(this.id)
      }
    }
  },
  methods: {
    gridClass() {
      if (this.type === 'history') {
        return 'grid1x1fr history-container';
      } else {
        return 'grid2x1fr';
      }
    },
    formattedMedium(medium) {
      if (medium.includes('Gesamtausfall')) {
        return 'allen Diensten';
      } else {
        return medium.join(', ').replace(/,(?=[^,]*$)/, ' und');
      }
    },
    formatDate(date) {
      const dateObj = new Date(date);
      return `${String(dateObj.getDate()).padStart(2, '0')}.${String(dateObj.getMonth() + 1).padStart(2, '0')}.${dateObj.getFullYear()} ${String(dateObj.getHours()).padStart(2, '0')}:${String(dateObj.getMinutes()).padStart(2, '0')}`;
    },
    toggleSearchBox() {
      this.isSearchBoxVisible = !this.isSearchBoxVisible;
    },
    async getDisturbances(){
      try {
        if (this.beginDate === '')
          this.beginDate = '1-1-1';
        if (this.endDate === '')
          this.endDate = '9999-1-1'
        let reverseOrder = "DESC"
        if (!this.reverseOrder)
          reverseOrder = "ASC"
        var response = await get('/Disturbance/GetDisturbances?beginDate='+this.beginDate+'&endDate='+this.endDate+'&medium='+this.medium+'&region='+this.region+'&solved=false&order='+reverseOrder);
        this.disturbances = response.data;
        this.formatDisturbances();
        console.log(this.disturbances);
      } catch (error) {
        console.log(error);
      }
    },
    async editRedirect(id){
      try {
        this.$router.push({ path: '/disturbance-editor/edit/' + id }).catch(() => {});
      } catch (error) {
        console.log(error);
      }
    },
    async clearRedirect(id){
      try {
        this.$router.push({ path: '/disturbance-editor/clear/' + id }).catch(() => {});
      } catch (error) {
        console.log(error);
      }
    },
    async showHistory(id){
      try {
        this.$router.push({ path: '/overview/history/' + id }).catch(() => {});
      } catch (error) {
        console.log(error);
      }
    },
    async getDisturbanceWithHistory(id){
      try {
        var response = await get('/Disturbance/GetDisturbanceWithHistory?id='+id);
        if (!response.data || response.data.length < 1 || typeof response.data[0] === 'undefined') {
          this.$router.push({ path: '/overview/all' }).catch(() => {});
        }
        this.disturbances = response.data.map((disturbance, index, array) => {
          disturbance.status = 'Zwischenmeldung';
          if (index === array.length - 1) {
            disturbance.status = 'Erstmeldung';
          }
          if (disturbance.solved) {
            disturbance.status = 'Freimeldung';
          }
          return disturbance;
        });
        
        this.formatDisturbances();
      } catch (error) {
        console.log(error);
      }
    },
    async redirectOverview(){
      try {
        this.$router.push({ path: '/overview/all' }).catch(() => {});
      } catch (error) {
        console.log(error);
      }
    },
    formatDisturbances(){
      this.disturbances.forEach(item => {
        item.medium = JSON.parse(item.medium);
        item.region = JSON.parse(item.region);
        item.subregion = JSON.parse(item.subregion);
        item.streets = JSON.parse(item.streets);
        item.cluster = JSON.parse(item.cluster);
        item.ubr = JSON.parse(item.ubr);
        item.mail_sent_to = JSON.parse(item.mail_sent_to);
      });
    }
  }
}
</script>

<style scoped>
@import "../assets/styles/overviews.css";

.grid2x1fr {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}

@media (min-width: 1268px) {
  .grid2x1fr {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1268px) and (max-width: 1600px) {
  .grid4x1fr {
    display: grid;
    grid-template-columns: 240px 1fr;
  }
}
</style>