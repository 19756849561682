<template>
  <div class="container">
    <div class="login-container">
      
      <div>
        <div class="form-group">
          <label for="name">Name</label>
          <input class="form-control small-input" type="text" id="name" name="name" placeholder="Name" @keyup.enter="login">
        </div>
        <div class="form-group">
          <label for="password">Passwort</label>
          <input class="form-control small-input" type="password" id="password" name="password" placeholder="Passwort" @keyup.enter="login">
        </div>
        <div v-if="errorMessage">
          <span class="text-red">{{this.errorMessage}}</span>
        </div>
        <div class="form-group">
          <button @click="login" class="btn btn-default btn-issue right">Log In</button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {post, get} from '../api.js';
export default {
  name: "LoginScreen",
  data() {
    return {
      errorMessage: ''
    };
  },
  methods: {
    async login() {
      try {
        var parameters = {
          username: document.getElementById("name").value,
          password: document.getElementById("password").value
        }
        const response = await post('/Auth/Login', parameters);
        console.log(response);
        this.$router.push({path: '/home'}).catch(() => {
        });
      } catch (error) {
        this.errorMessage = "Anmeldung fehlgeschlagen";
        console.log(error);
      }
    }
  }
}
export async function validateToken() {
  try {
    const response = await post('/Auth/ValidateToken');
    return response.statusCode === 200;
  } catch (error) {
    console.error('Token validation error:', error);
    return false;
  }
}

export async function userIsAdmin() {
  try {
    const response = await get('/Auth/GetRoleByAppUser');
    return response.data;
  } catch(error) {
    console.error(error);
  }
}

export async function getUserName() {
  try {
    const response = await get('/Auth/GetUsername');
    return response.data;
  } catch(error) {
    console.error(error);
  }
}
</script>

<style scoped>
.admin-container {
  background-color: #fff;
  margin: 2%;
  padding: 10px;
}

.center {
  margin: auto;
  vertical-align: center;
  width: 50px;
}

.login-container {
  width: 400px;
  height: 250px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -200px;
  margin-top: -125px;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
}

.register-container {
  width: 400px;
  height: 450px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -200px;
  margin-top: -225px;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
}

.right {
  display: block;
  margin-left: auto;
  margin-right: 0;
}

.small-input {
  width: 100%;

}
</style>